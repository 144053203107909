import { useEffect, useState } from "react";
import { Wrapper, Text } from "./App.styles";

export interface TextStyles {
  size: string;
  color: string;
  weight: string;
  rotate: string;
  backgroundColor: string;
  borderColor: string;
  font: string;
}

const randomIntFromInterval = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const colorsList = [
  "red",
  "blue",
  "green",
  "yellow",
  "DeepPink",
  "aqua",
  "Chartreuse",
  "Crimson",
  "GreenYellow",
  "Magenta",
  "Lime",
  "OrangeRed",
  "MediumTurquoise",
  "Violet",
  "Teal",
  "White",
  "SlateBlue",
  "Salmon",
  "PaleGoldenRod",
  "Olive",
  "Gold",
  "Fuchsia",
];

const fontsList = [
  "arial",
  "comic sans ms",
  "Times New Roman",
  "Courier New",
  "Brush Script MT",
];

const getRandomColor = () =>
  colorsList[randomIntFromInterval(0, colorsList.length - 1)];

const getRandomFont = () =>
  fontsList[randomIntFromInterval(0, fontsList.length - 1)];

const generateRandomStyles = (): TextStyles => {
  return {
    size: `${randomIntFromInterval(20, 200)}px`,
    color: getRandomColor(),
    backgroundColor: getRandomColor(),
    borderColor: getRandomColor(),
    weight: `${randomIntFromInterval(1, 9)}00`,
    rotate: `${randomIntFromInterval(-360, 360)}deg`,
    font: getRandomFont(),
  };
};

function App() {
  const [styles, setStyles] = useState<TextStyles>(generateRandomStyles());

  useEffect(() => {
    setInterval(() => {
      setStyles(generateRandomStyles());
      console.log("test");
    }, 500);
  }, [setStyles]);

  return (
    <Wrapper {...styles}>
      <Text {...styles}>Du coup</Text>
    </Wrapper>
  );
}

export default App;
